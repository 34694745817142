import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import brand_icon from 'src/images/brand_icon.svg'

const NavContainer = styled.div `

  display: flex;
  width: 100%;
  justify-content: left;
  flex-wrap: wrap;
  flex-direction: row;

  @media (min-width: 1024px) {
    padding: 0;
    justify-content: center;
    flex-direction: column;
    border: 0 none;
  }
`

const BrandBar = styled.div `
  display: flex;
  font-size: 1.5rem;
  text-decoration: none;
  color: #000;
  margin-top: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 1.5rem 1rem;
  width: 100%;

  @media (min-width: 1024px) {
    margin-top: 1.5rem;
    font-size: 2rem;
    justify-content: center;
  }
`

const Brand = styled(Link) `
  display: flex;
  text-decoration: none;
  color: #000;
`

const BrandIcon = styled.span `
  /* display: none; */
  background-image: url(${brand_icon});
  background-size: 18px;
  display: inline-block;
  width: 18px;
  height: 24px;
  margin-right: 0.675rem;
  margin-top: 0.5rem;

  @media (min-width: 1024px) {
    background-size: 24px;
    width: 24px;
    height: 32px;
    margin-right: 0.75rem;
    margin-top: 0.675rem;
  }
`

const BrandL = styled.span `
  font-weight: 700;
`

const BrandR = styled.span `
  font-weight: 300;
  margin-left: 0.375rem;
`

const NavIcon = styled.div `
  position: absolute;
  right: 0;
  margin: 0.5rem 1.5rem 0;
  width: 20px;

  &:after, &:before, & div {
    background-color: #000;
    border-radius: 3px;
    content: '';
    display: flex;
    height: 3px;
    margin: 4px 0;
    transition: all .2s ease-in-out;
  }

  &.active:before {
    transform: translateY(7px) rotate(135deg);
  }

  &.active:after {
    transform: translateY(-7px) rotate(-135deg);
  }

  &.active div {
    transform: scale(0);
  }  

  @media (min-width: 1024px) {
    display: none;
  }


`

const NavBar = styled.nav `
  /* position: absolute; */
  /* margin-top: 71px; */
  display: ${props => props.display};
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  background: #fff;
  z-index: 999;

  @media (min-width: 1024px) {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #F2F2F2;
    flex-wrap: wrap;
  }
  
`

const NavItem = styled(Link) `
  border-bottom: 1px solid #f2f2f2;
  font-size: 1.25rem;
  font-weight: 400;
  text-decoration: none;
  color: #777;
  padding: 0.75rem 1.25rem;
  text-transform: lowercase;
  margin-left: 2px;

  &.active {
    color: #000;
    background: #fafafa;
    border-left: 2px solid #C02907;
    margin-left: 0;
  }

  @media (min-width: 1024px) {
    margin: 0 1.25rem;
    padding: 0.5rem 0rem;
    border: 0 none;
    
    &:hover {
      color: #C02907;
      border-bottom: 2px solid #C02907;
      margin-bottom: -2px;
    }

    &.active {
      color: #000;
      border-bottom: 2px solid #C02907;
      margin-bottom: -2px;
      border-left: 0px none;
      background: none;
      margin: 0 1.25rem;

      &:hover {
        color: #C02907;
      }
    }
  }
`

class Nav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navMenuVisible: false
    }

    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
  }

  handleMenuButtonClick(e) {
    e.preventDefault();
    this.setState({ navMenuVisible: !this.state.navMenuVisible });
  }

  handleCloseButtonClick(e) {
    this.setState({ navMenuVisible: false });

  }

  render() {
    let navDisplay = this.state.navMenuVisible ? "flex" : "none"
    let navActive = this.state.navMenuVisible ? "active" : ""

    return (
      <NavContainer>
        <BrandBar>
          <Brand to="/">
            <BrandIcon />
            <BrandL>cristina</BrandL>
            <BrandR>hartmann</BrandR>
          </Brand>
          <NavIcon onClick={ this.handleMenuButtonClick } className={navActive}>
            <div></div>
          </NavIcon>
        </BrandBar>
        
        <NavBar display={navDisplay}>
          <NavItem to="/" activeClassName="active" partiallyActive={false} onClick={this.handleCloseButtonClick}>Home</NavItem>
          <NavItem to="/blog" activeClassName="active" partiallyActive={true} onClick={this.handleCloseButtonClick}>Blog</NavItem>
          <NavItem to="/stories" activeClassName="active" partiallyActive={true}>Stories</NavItem>
          <NavItem to="/bookshelf" activeClassName="active" partiallyActive={true}>bookshelf</NavItem>
          <NavItem to="/about" activeClassName="active" partiallyActive={false} onClick={this.handleCloseButtonClick}>About</NavItem>
          <NavItem to="/contact" activeClassName="active" partiallyActive={false} onClick={this.handleCloseButtonClick}>Contact</NavItem>
        </NavBar>
      </NavContainer>
    )
  }
}

export default Nav;