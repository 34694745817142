import React from 'react'
import styled from 'styled-components'
import Nav from '../components/nav'

const Container = styled.div `
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
` 

const SectionLabel = styled.span `
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: lowercase;
`

const AsideSectionLabel = styled.span `
  /* font-size: 1.25rem;
  font-style: italic;
  font-weight: 700; */

  /* font-family: Lato;
  font-weight: 900;
  font-size: 1rem;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: 1px; */

  font-size: 1.5rem;
  font-weight: 300;
  text-transform: lowercase;
`

const ContentContainer = styled.div `
  /* max-width: 1024px; */
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  @media (min-width: 1024px) {
    padding: 0;
    flex-direction: row;
  }
`

const MainContainer = styled.div `
  display: block;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;

  @media (min-width: 1024px) {
    width: 640px;
    margin: 2.5rem 0;
    padding: 0 2.5rem 0 0;
  }
`

const AsideContainer = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2.5rem 0;

  @media (min-width: 1024px) {
    width: 384px;
    padding: 0 0 0 2.5rem;
    border-left: 2px solid #f2f2f2;
  }
`

const Layout = ({ children }) => (
  <Container>
    <Nav />
    <ContentContainer>
      { children }
    </ContentContainer>
  </Container>
)

export { Layout, MainContainer, AsideContainer, SectionLabel, AsideSectionLabel }